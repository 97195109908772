import { h } from 'preact'

export default function NativeIcon() {
  return (
    <svg viewBox="0 0 35 31" style={{ height: '55%', marginTop: '-2px' }}>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path
          d="M0.4,16.0972376 L0.938674033,16.7187845 L6.15966851,12.0364641 L6.15966851,30.6 L14.1569061,30.6 L14.1569061,19.1635359 L21.201105,19.1635359 L21.201105,30.6 L29.2812155,30.6 L29.2812155,12.0364641 L34.5022099,16.7187845 L35.040884,16.0972376 L17.720442,0.6 L0.4,16.0972376 Z M28.4524862,29.7712707 L22.0298343,29.7712707 L22.0298343,18.3348066 L13.3281768,18.3348066 L13.3281768,29.7712707 L7.02983425,29.7712707 L7.02983425,11.2906077 L17.7618785,1.71878453 L28.4939227,11.2906077 L28.4939227,29.7712707 L28.4524862,29.7712707 Z"
          fill="var(--icon-color)"
          fill-rule="nonzero"
        ></path>
      </g>
    </svg>
  )
}
