import "src/ui/components/JobsSubscribeWidget/styles.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/ui/components/JobsSubscribeWidget/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA41Uy67bIBTc5yuQ7qZdOLIdOzcmm6pdVeo/RASObRQMFuAkt1X+vTJ+EWK1dwtn5syc19bYDwHmRJW0hEvQp1PSldc8r2L0Z4PQmdBLpVUnGUZvZc6K/HDcINQSxrisMIq3aa6hOW4em+1IVQNhHk/ieEolbWT4b8Ao2cYDBCHBJUQ18Kq2/Xt2nCJv49s+jn3qs1D0MjOnKwppmVHS01i424gBVZpYriRGUknoPxg3rSAfGJUC7i6hgHvEuAY6BFIlukb2P1QJpTE6C0IvjlMTafgQtaRFSR43BgExEKnO+npLpaxXip0TPLK+HXbv2S5etzzqJ4JXvSKQFvSTeFeJoBN9VdFCOFQ73h6C/jjoDyUtSDtLy5y0mSwJMKXSzRybu9iXOjqxEbfQGF9yQ3TF5aIPPXWUy7ZbVOyHWRFwx4h0VvX4aTzScWae+12Ue9gFlpMxcinNmNzBlWagI00Y7wweyhX2eZAc6SFxiMUoae/IKMHZBFn8mO5sqOZn+N5Zq+Ts7N1zNk1i6GzFwmu/ffuz4NHAreYWfKFxUIaJlnba9IBW8alNq937hC9cqytof7A1MB9pb9xa0D+phzq4+BtntsYozdr7a9Xz4bFV08JpEMTyq/NnVYvRvo94yfOLy+VCFL6uZY3XT8Nj860Bxgn60mooQZvIASNDa2gAI0b05asj3P7naAZDus8SFrshfXjg1TO5XAcG7J0UIWjtAAbZsiTZxWkIXL1ESzZyIFnxInFtOecNmqBpEY/uAiH+RzigfpZ/r8xaRYJERZrvX+v7mYldE/b4Cym4WdcTBwAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var block = 'styles_block__1ufv55g2';
export var blockContent = 'styles_blockContent__1ufv55g4';
export var container = 'styles_container__1ufv55g0';
export var footer = 'styles_footer__1ufv55g3';
export var form = 'styles_form__1ufv55g5';
export var header = 'styles_header__1ufv55g1';
export var input = 'styles_input__1ufv55g6';
export var subscribeButton = 'styles_subscribeButton__1ufv55g7';
export var twitterIcon = 'styles_twitterIcon__1ufv55g8';
export var twitterLink = 'styles_twitterLink__1ufv55g9';