import { h } from 'preact'

export default function ConsistentIcon() {
  return (
    <svg viewBox="0 0 32 30" style={{ height: '55%', marginTop: '-1px' }}>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path
          d="M16.1695005,18.7467082 C21.1307052,18.7467082 25.1525563,14.6324008 25.1525563,9.55714537 C25.1525563,4.48188995 21.1307052,0.367582514 16.1695005,0.367582514 C11.2082957,0.367582514 7.18644466,4.48188995 7.18644466,9.55714537 C7.18644466,14.6324008 11.2082957,18.7467082 16.1695005,18.7467082 Z"
          stroke="var(--icon-color)"
        ></path>
        <g
          transform="translate(0.359322, 10.659893)"
          stroke="var(--icon-color)"
        >
          <path d="M8.98305582,18.3791257 C13.9442606,18.3791257 17.9661116,14.2648183 17.9661116,9.18956285 C17.9661116,4.11430743 13.9442606,0 8.98305582,0 C4.02185108,0 0,4.11430743 0,9.18956285 C0,14.2648183 4.02185108,18.3791257 8.98305582,18.3791257 Z"></path>
          <path d="M22.2779784,18.3791257 C27.2391832,18.3791257 31.2610343,14.2648183 31.2610343,9.18956285 C31.2610343,4.11430743 27.2391832,0 22.2779784,0 C17.3167737,0 13.2949226,4.11430743 13.2949226,9.18956285 C13.2949226,14.2648183 17.3167737,18.3791257 22.2779784,18.3791257 Z"></path>
        </g>
      </g>
    </svg>
  )
}
