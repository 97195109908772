import { h } from 'preact'

export default function DocsIcon() {
  return (
    <svg viewBox="0 0 23 30">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g fill-rule="nonzero" fill="var(--icon-color)">
          <path d="M6.34883721,21.1046512 L6.34883721,24.9418605 C6.34883721,27.5930233 8.51162791,29.755814 11.1627907,29.755814 L11.1627907,29.755814 C13.8139535,29.755814 15.9767442,27.5930233 15.9767442,24.9418605 L15.9767442,21.1046512 C19.744186,19.255814 22.1511628,15.4534884 22.1511628,11.2325581 C22.1511628,5.1627907 17.2325581,0.244186047 11.1627907,0.244186047 C5.09302326,0.244186047 0.174418605,5.1627907 0.174418605,11.2325581 C0.174418605,15.4534884 2.58139535,19.255814 6.34883721,21.1046512 Z M15.2790698,21.1744186 L15.2790698,24.8372093 L7.04651163,24.8372093 L7.04651163,21.1744186 L15.2790698,21.1744186 Z M11.1627907,29.0581395 L11.1627907,29.0581395 C9.10465116,29.0581395 7.39534884,27.5232558 7.11627907,25.5697674 L15.244186,25.5697674 C14.9302326,27.5232558 13.2209302,29.0581395 11.1627907,29.0581395 Z M11.1627907,0.941860465 C16.8488372,0.941860465 21.4534884,5.54651163 21.4534884,11.2325581 C21.4534884,15.1744186 19.1860465,18.7674419 15.627907,20.4767442 C15.627907,20.4767442 15.627907,20.4767442 15.5930233,20.4767442 L11.5116279,20.4767442 L11.5116279,12.3139535 L14.2325581,12.3139535 C14.4418605,12.3139535 14.5813953,12.1744186 14.5813953,11.9651163 C14.5813953,11.755814 14.4418605,11.6162791 14.2325581,11.6162791 L8.09302326,11.6162791 C7.88372093,11.6162791 7.74418605,11.755814 7.74418605,11.9651163 C7.74418605,12.1744186 7.88372093,12.3139535 8.09302326,12.3139535 L10.8139535,12.3139535 L10.8139535,20.5116279 L6.69767442,20.5116279 C6.69767442,20.5116279 6.69767442,20.5116279 6.6627907,20.5116279 C3.13953488,18.7674419 0.872093023,15.1744186 0.872093023,11.2325581 C0.872093023,5.54651163 5.47674419,0.941860465 11.1627907,0.941860465 Z"></path>
        </g>
      </g>
    </svg>
  )
}
