import { h } from 'preact'

export default function ModularIcon() {
  return (
    <svg viewBox="0 0 26 34" style={{ marginTop: '-1px' }}>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path
          d="M17.137931,20.9265862 C17.137931,19.1543103 17.9791724,17.7453448 19.3604138,17.137069 C20.7424828,16.5292069 22.3,16.8561034 23.5033103,17.953069 C23.8893793,18.3043793 24.2266207,18.4732069 24.5791724,18.308931 C24.9408276,18.1401034 25,17.521069 25,17.1362414 L25,10.532931 C25,9.65031034 24.3445517,9.01968966 23.4615172,9.01968966 L16.986069,9.01968966 C14.8264828,9.01968966 14.3452414,7.20024138 15.450069,5.94851724 C17.2691034,3.88782759 16.2946207,0.701206897 12.9846897,0.563 C9.67517241,0.700793103 8.70068966,3.88203448 10.5197241,5.94313793 C11.6245517,7.19527586 11.1437241,9.01968966 8.98413793,9.01968966 L2.60427586,9.01968966 C1.72165517,9.01968966 1,9.65031034 1,10.532931 L1,17.0932069 C1,17.4780345 1.17255172,18.1256207 1.54124138,18.2973448 C1.90786207,18.4678276 2.38206897,18.2898966 2.77186207,17.9344483 C3.97103448,16.8412069 5.58151724,16.5366552 6.94868966,17.1383103 C8.32289655,17.7441034 9.27586207,19.1671379 9.27586207,20.9443793 L9.27586207,20.9758276 L9.27586207,20.9965172 C9.27586207,22.7683793 8.31172414,24.1777586 6.93048276,24.7860345 C5.54841379,25.3943103 3.93048276,25.0674138 2.72717241,23.9704483 C2.34151724,23.6183103 1.88924138,23.4503103 1.53668966,23.6141724 C1.17462069,23.7834138 1,24.4024483 1,24.7872759 L1,31.3901724 C1,32.2727931 1.72165517,33.0196897 2.60427586,33.0196897 L23.4615172,33.0196897 C24.3445517,33.0196897 25,32.2727931 25,31.3901724 L25,24.8298966 C25,24.445069 24.9118621,23.7974828 24.5431724,23.6257586 C24.1769655,23.4552759 23.7449655,23.6332069 23.3547586,23.989069 C22.1564138,25.0823103 20.6886897,25.3864483 19.3215172,24.7847931 C17.9473103,24.1794138 17.137931,22.7563793 17.137931,20.9787241 L17.137931,20.9472759 L17.137931,20.9265862 L17.137931,20.9265862 Z"
          stroke="var(--icon-color)"
        ></path>
      </g>
    </svg>
  )
}
