import { h } from 'preact'

export default function ImmutableIcon() {
  return (
    <svg viewBox="0 0 24 31" style={{ height: '55%', marginTop: '-1px' }}>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path
          d="M21.7423978,13.7492759 L20.1595421,13.7492759 C19.9566119,13.7492759 19.7536817,13.5869318 19.7536817,13.3434155 L19.7536817,9.60949964 C19.7536817,5.30737917 16.4256263,1.65463537 12.1235058,1.4922912 C7.61845509,1.32994703 3.88453921,4.94210479 3.88453921,9.44715548 L3.88453921,13.3840016 C3.88453921,13.5869318 3.72219504,13.789862 3.47867879,13.789862 L1.89582314,13.789862 C1.28703251,13.789862 0.8,14.2768945 0.8,14.8856851 L0.8,29.2531441 C0.8,29.8619347 1.28703251,30.3489672 1.89582314,30.3489672 L21.7018117,30.3489672 C22.3106024,30.3489672 22.7976349,29.8619347 22.7976349,29.2531441 L22.7976349,14.8450991 C22.8382209,14.2363085 22.3511884,13.7492759 21.7423978,13.7492759 L21.7423978,13.7492759 Z M6.36028778,9.44715548 C6.36028778,6.32203023 8.95779448,3.80569561 12.1235058,3.96803978 C15.0862869,4.13038395 17.3591052,6.64671856 17.3591052,9.60949964 L17.3591052,13.3840016 C17.3591052,13.5869318 17.1967611,13.789862 16.9532448,13.789862 L6.72556216,13.789862 C6.52263195,13.789862 6.31970174,13.6275178 6.31970174,13.3840016 L6.31970174,9.44715548 L6.36028778,9.44715548 Z"
          stroke="var(--icon-color)"
        ></path>
      </g>
    </svg>
  )
}
