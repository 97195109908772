import "src/ui/components/BooksWidget/styles.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/ui/components/BooksWidget/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5WUUa7aMBBF/1mFpf60lYIcHgkvRuoKqm4BOfYkcTF2ZE8KtHp7rxwgOJAC/XWS4zv3zs3c41GD35Rc1rDZpKtcGicp+TMjpORiWzvbGcnIp4LTqnhfzwhpuZTK1IzQ+SJzsAtnUvlW8yMjlYZDOPjZeVTVMRHWIBhkxLdcQFIC7gFMeINrVZtEIew8IwIMglvPPmbzWNB3XoIeVKW9KmG1dYzsG4UQOJU1mOxB1Q0yklN6B/kBBxwYi7vJ0HHjW+7A4HoCX1onwTGStgfirVby9lHiuFSdH9khOucDpbVqcq6xJNbYX+Di4SK3Y6nnm68wET4cQG89Yq8kNozkZykhkMQ3TpktIyNzJHjhVIvKmgGx7BFaGUias6XpfDnY7NVvCIMWlznPcitZLaUYKzPIlYnUZT06Wp6LWaM1eytonvERu6ryKp9cstEKnQZF7voYEQ6YSBDW8TAgI8aaPrQ+bnU6u95M0ozuPAHuIbEdPpkkCmwkPuc0Wy2e2fCNfGXG4memucdENErLLz1rx12tTOJOvtP56uTQldYAlxEqf4oqLaLdBVa6uIVpZbYDanU/DHCaFcVU4aPshkiU6Zem1FZsp2oUrU962cy4uFko7qNKvZjbtbCXwB908d/RvmpSVVHKs5iJcbXfp+q0ePD664HexokK9fUHXvQf3f8ax0FEP6xz2YDLFa/+z6Opm+/q1xkJLhixnn38BfVXVdd0BgAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var badge = 'styles_badge__176dnrd0';
export var badgeLabel = 'styles_badgeLabel__176dnrd1';
export var badgeNext = 'styles_badgeNext__176dnrd2';
export var container = 'styles_container__176dnrd5';
export var cover = 'styles_cover__176dnrd3';
export var description = 'styles_description__176dnrd4';
export var header = 'styles_header__176dnrd6';
export var link = 'styles_link__176dnrd7';
export var text = 'styles_text__176dnrd8';
export var title = 'styles_title__176dnrd9';