import "src/ui/components/JobsWidget/styles.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/ui/components/JobsWidget/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VWTY+jOBC996+w1BppZiUivpMQaTSzcxqptdc9toxdgDvGRsbZdHbV/32FiQMYSPfunBAf9arq+dUrNq2+cGifiRQaMwHq+fmCT/sX8NE/DwjlmBxLJU+CZuixKIqE7A7dY6koKE/LJkNB84payRlFj5CCD+nh4e1hc4XNuSRHCxksQuZgIDW8ao8CkQprJkWGhBTQvaCsbTi+ZKjg8No96K4eZQpI/yGR/FSL7k2Ju3oU1N0NkVyqDOUck6NJoLBoWR8y1ICCxK9bBLgFT55092GNVclEhvxNmFyxGkwpE+UA3hMwaT6HPEnxrPkfnJEjzjlYFsKskn+BWuICCpyMAWrMhA2LTMCMDMxZKTymoW4zREBoUN3jl1OrWXHxumMFoTPUNpiAl4M+A4j1HF/Rb5mQ+nPGcas9UjFOv5jEPSmeYmWlLQ0DCJF1g8XlpyikxYr/U72jk5uhfhf0SRKjCgueLIOvK2PGh5P6dtZD9oopJsonnAO3aVOT9iqsx8gPd35g8kqhvZb9Das9/IHrmwC2BsXEnKHnM/X9cRB32t1N8u6KHd6GTl5/s3cy46bhF4uwNwg3GfubrttBzY4McbQ9LDQ6YBdS6sEq8PJpvKfBVTG4ZfaXWcM7p+G+qCcmboaTT3mLtnHkTyzFTKiTMjIp380m4FX/ftJ6OCWyTMOE3LRIaRqOyD1XTMPYUXynnCB0OCAn1XaRjWSWsF82gYVmVlzqaqdLoZNRoQuu0XM7mTHAdNARDINxHaaNb7vmTIBXXccl2MSH90ZoJINiRQarK2eabBV2RNG9ZWPezfbMeNpL+afCTTMwURrQM6O6ylASN0ZItiB7f13CClN2ajOU+J+6p11JBZfnDFWM0n7Oxgd4E9z/XiX21bQDW3q1VLox5rZSTByNwqfy+UkG3bFxuGumGpf2u5f5/gQKKUnHxhXHcdhb2Wig7DzFk1U+0Hit+N7sa1y2tpDjB81vztoU5YOb1/rA28O3GijD6HOjoADVeqZpryUV1JAhitWxj9/c/8NzSAyovw3MdN2YsWyGJEhD411vI9iFPzwHMiz8KMCLkPE2DKJ0EfLuf5OTIfGDIMxdmNUFPgikKIq0IG7g8vodovZ0t98nbtTCvnXK3EVhGtODUwGNAFysteUyikshAkvqL7F93/vni+pD3M/tdyid5MQn27sRSwWMWh7HzTzBqS8OwiiKXNKHY3/7F65/hRQCDQAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var apply = 'styles_apply__yau9je9';
export var block = 'styles_block__yau9je1';
export var blockClickable = 'styles_blockClickable__yau9je2';
export var companyAndLocation = 'styles_companyAndLocation__yau9je5';
export var companyInfo = 'styles_companyInfo__yau9je4';
export var companyName = 'styles_companyName__yau9je7';
export var container = 'styles_container__yau9je0';
export var footer = 'styles_footer__yau9jea';
export var footerLink = 'styles_footerLink__yau9jeb';
export var header = 'styles_header__yau9jee';
export var hiringLabel = 'styles_hiringLabel__yau9je6';
export var link = 'styles_link__yau9jef';
export var location = 'styles_location__yau9je8';
export var logo = 'styles_logo__yau9jeh';
export var logoWrapper = 'styles_logoWrapper__yau9jeg';
export var main = 'styles_main__yau9je3';
export var nextButton = 'styles_nextButton__yau9jec';
export var nextButtonLabel = 'styles_nextButtonLabel__yau9jed';
export var nextIcon = 'styles_nextIcon__yau9jei';
export var tag = 'styles_tag__yau9jej';
export var tags = 'styles_tags__yau9jek';