import { h } from 'preact'

export default function I18nIcon() {
  return (
    <svg viewBox="0 0 195 206" style={{ height: '50%' }}>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <path
          d="M181.884,0.368 L194.556,0.368 L194.556,206 L181.884,206 L181.884,0.368 Z M0.444,0.368 L13.116,0.368 L13.116,90.512 L87.708,90.512 C106.908096,90.512 121.979945,95.5039501 132.924,105.488 C143.868055,115.47205 149.34,129.679908 149.34,148.112 C149.34,156.944044 147.900014,164.911964 145.02,172.016 C142.139986,179.120036 137.964027,185.215975 132.492,190.304 C127.019973,195.392025 120.444038,199.279987 112.764,201.968 C105.083962,204.656013 96.444048,206 86.844,206 L0.444,206 L0.444,0.368 Z M85.692,195.056 C101.436079,195.056 113.867954,190.976041 122.988,182.816 C132.108046,174.655959 136.668,163.088075 136.668,148.112 C136.668,133.135925 132.156045,121.61604 123.132,113.552 C114.107955,105.48796 101.724079,101.456 85.98,101.456 L13.116,101.456 L13.116,195.056 L85.692,195.056 Z"
          fill="var(--icon-color)"
        ></path>
      </g>
    </svg>
  )
}
