import "src/ui/components/Home/styles.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/ui/components/Home/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61VTY+bMBS851dYqirtHogMCSFxLlXbQ/9F5I8HWAEb2WY322r/ewWEAIZNo1WvNjPvMTPveW3dWwH2RLmTWp1OUVxnrzuM/qwQ4rrQhqAvex6yeHtcIVRSk0kVOF0RFOHqcly9r9ZXBlY7p9V3aqFnCVuWMWbTYhAS0lYFfSOIFZqfmxMHFxfQQmaKIA7KgbmdCuDa0KY9gpRW0FwwbQSYwFAha0vQpqNNtXKBlb+BoHDXHVVUCKkygsK4ulzr+z2fKiNLat76vqO2b0b5OTO6VmIiQS9KmqZDIwSF1QVZXUgxfDsrY4FrJUaFNrNCzlBlK2pAueOiAw/Vy4EKMH2V7cTMJME83t3UegWZ5Y6gHcaegr6/tma/JrzxB7xjw/F6b6D0vWmPBmapFJgfWjkq1UC/u6bnErxK4XKCwghjz9RkMHUUqrSALg4FXAIhDfAuPFwXdamamzZogXRQ2iFuQ0OFVOe+jWQ6CrtIxOxONKckP7t7ED3bvmWbYWslwBRyStCNpO2hhxY6+8l//MqMhCjtnkhBrQt4LgvxPB5S04XBN6id0p6CtoDeE4y//g/tbxPNtHO6nOQbtrAV4uN+Rj/TzdOUyffFAyuXd9gneAH17E1kMBp3lsYP8Wgh7tGIlI5puFYOlOuJ2LLLn46yT9/Zn0qz6P9oSQ8UTVp7PG+/XtizTXiD/LpMoqg7XF7qvRZbHh42/Dgd8hjfrS7LbNzvLS1eYKeg6hMQP1QeGjfQbyUISdFTZSAFYzuTA8tzKIEgQc25E3d9540d5BCbQ8LaBfo+wtx/OgY0Y3tKo2N7dh2A+dWYeOmNGDUjRCQOPuaDJ2D0KDKR0NCHzdfpgICQ7nl8F0Fy/QLmMbXmi2omR8TDXTST44GtsDjQIcUbnD7MdtsNy2QRjjD1yebzN8jAGcc86RDvfwGwDcBGzAkAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var action = 'styles_action__25ugw60';
export var actions = 'styles_actions__25ugw69';
export var block = 'styles_block__25ugw6a';
export var button = {primary:'styles_button_primary__25ugw62 styles_buttonBase__25ugw61',secondary:'styles_button_secondary__25ugw63 styles_buttonBase__25ugw61'};
export var buttonBase = 'styles_buttonBase__25ugw61';
export var content = 'styles_content__25ugw6b';
export var header = 'styles_header__25ugw64';
export var innerContainer = 'styles_innerContainer__25ugw66';
export var link = 'styles_link__25ugw67';
export var linkDecorated = 'styles_linkDecorated__25ugw68';
export var subHeader = 'styles_subHeader__25ugw65';
export var text = 'styles_text__25ugw6c';