import "src/ui/screens/Docs/Doc/TypeDoc/Types/styles.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/ui/screens/Docs/Doc/TypeDoc/Types/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA5VWS2/jOAy+51cI6KUDrAO/YyuXwd7mNljsvZAlytFUtryS0jS76H9fWH7EjzjtnBxQIr+P/Egqe2OvEszLRZOmAf3yov7x8/yXj/7bIXQCUZ4sRpWonymR9Dnw/bcT8lCmofr2B0r89nvcIcSEaSS5YlRqwVpD+/UsVI0kFjytLgYjcrYKBVwfdx+7fY9rhZUwoAYOtSD0tdTqXDOMnqCAgGVtxEJpBtorlLWqwiho3pFRUjD0BCn4kLZ3GsKYqEuM9omGCgUaqtbMVW09I/4FPDdd+vxS329tVEmlMXo6FCk7HFYsf1BVD0xDx/QimD1hFOzDPuoQIWNpFgWOkTLCClVjpEESK96gtVrVYBQ27+3viuhS1J7uqOzDxMVaYP8kGmo7oEcO3aXASSXkFaNK1co0hMLUtSJiZBw7n090okqeq9pgFEZt/VrBtsR0OiKk3kBzqS4YnQRjUE/hhYVqgE8cvIV36zGgSpOuKLWqZ4xr8jZ4pF0zdKr31bkr+m/23mPOUpixzAfHYLjtXXFfkAld9ReYs7RmcMluaRIpyhojCrUFPWvOYKEwVbWdqJu7GOPtobce8VhFOHUt0vfWODN9qD72aPf3SX+yPWRJFsdJGJAp7AkIu+0M4iDHITr4NEmPXycR/AaFiehcghujX2djBb96fSkwctPgFWAv0EqMkBPEa5vS3GRZJvM3vI9lLG5j1i+PsU6fz15BWAl/EjMuN7qOtigFRs6C9tG8FJowcTYYJd3C6KzTyhSS0NdJqSVw2+/AT3aQF7QxF6xfiBRkbGk2kzWIUz9NJ+SGg4RAyt3Ku+3v8ZAlnHK+BhKtCJzQsUgwA/P9OAuiO2A5KzhJN8Ag5AfI1mAl1KAFHaD4vF2zlPqHO1CMsTjfyotzTtjspTDnqiL6OoCUK9H72f9eARMEPTcaOGjTRfQMPUEFGDGiX7851wfv5OKlDDM/DJJjdzDPIaRBGobd0WCjBfWpS/hjCbN+6CZueZ4n4dJttbYfUJj6be2tL7rf2z9fSp5znnK6DLfZ/NOOLKIiulvkmKUkJceFMOOxzwPSra415MYYzGG7/yh3YWP2ENbn92HvDsQNtIhIvgkasyjaBG0z7Tw/dh//AyiY/VJiCgAA\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var badge = {alias:'styles_badge_alias__oq099jd styles_badgeBase__oq099jc','interface':'styles_badge_interface__oq099je styles_badgeBase__oq099jc',generic:'styles_badge_generic__oq099jf styles_badgeBase__oq099jc'};
export var badgeBase = 'styles_badgeBase__oq099jc';
export var content = 'styles_content__oq099j9';
export var header = 'styles_header__oq099ja';
export var headerText = 'styles_headerText__oq099jb';
export var item = 'styles_item__oq099j5';
export var list = 'styles_list__oq099j7';
export var main = 'styles_main__oq099j4';
export var nav = 'styles_nav__oq099j6';
export var noResults = 'styles_noResults__oq099j8';
export var summary = 'styles_summary__oq099jg';
export var title = 'styles_title__oq099j1';
export var titleIcon = 'styles_titleIcon__oq099j2';
export var titleParent = 'styles_titleParent__oq099j3';
export var wrapper = 'styles_wrapper__oq099j0';