import "src/ui/screens/Home/Promo/styles.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/ui/screens/Home/Promo/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA4VVzY7aMBC+8xQjoWoXibCOQ8ISLr1W2vbSB1g59ji4JHbqmIVtte9e5Y+EELonhDOe72c+26vSvWdYvpqjQ/v6Kn/bkCgCf2cAJyXcPgafkC+7GQA3mbExnPbKYfU/YfyQWnPUIoZ5yEgYkOtlT+UsxRiONnt8eCodc4o/0SCkEd1ywhIm2DahLNqIjQxIwkJGfb4qdPqwGDUq1R+MgZs3tKMvhSmVU0bH4EwBHLVrSqpSmZmT9x4DOzqzm33MVq1UpXUv1a+lDrVkSiOzXmqZUKjdo00T9uj7z0vwyRIifwlktVksYb7mZE2TmmvBhFA6jWFtMQffYl6tskyl2lMO8zIecBOqLDL2HoPM8FwtVL+eUBZ5I4Wb7Jjr6suvY+mUfPe40Q6167v0ajKTmk4MrcXc9L/D425zgD2qdO9iCILiPAb7Vk21QwyGSVmT2+ofLL8Ur+tiabRrJxqExXkQrbmUctdVnFoKa1LHKmc2VdrLULoqks2+fvoWM+bUWx1MZ4oYPP+ayx6Z6Kce1kwcnp1XmzPUfiGTiA3zd9eMaYvc0qmhaDvuK9pBRbuHr6A68Ghsgx81TavkeZ31lDaLV11pY8Y08SGnLoK9s7jl26bq7LXzCsloYCk6p3T60zHrUHR8N9OpGl0P/0lTQ6wxCsh9wBelDx3o8zVokhl+uLl1nrmfhOv7jtSrArmxrImJNhonApcYK9B61Yk/ljEEA+fbCbV2Xs55t9CZ0GSgF6bx9MISzDo522kP25ElxjmT90m6y7shGoNfnKE0mRK1BCllFEzouGFNx8ctQSEnpd4q+Y5lOTj47NN7s53OEuahJCQgi6lHZAC82k6do7BJ/EjZdkJZeyt8zL7mKBSDx8KiRFt6NaZX8j3mGINg9rCoya/uP30XwG4yA8Mp9yNKK/SPQZOJR+UTeygjob8R0RLmPiWUsMW459SddZXdSPLxntuLpt/BE074ptnx8Q+yoi+++QcAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var gettingStarted = 'styles_gettingStarted__fqr50i7';
export var gettingStartedLink = 'styles_gettingStartedLink__fqr50i8';
export var header = 'styles_header__fqr50i5';
export var inner = 'styles_inner__fqr50i1';
export var logo = 'styles_logo__fqr50i2';
export var logoImage = 'styles_logoImage__fqr50i3';
export var logoName = 'styles_logoName__fqr50i4';
export var newLabel = 'styles_newLabel__fqr50i9';
export var newLabelMessage = 'styles_newLabelMessage__fqr50ia';
export var outer = 'styles_outer__fqr50i0';
export var text = 'styles_text__fqr50i6';