import "src/ui/screens/Docs/NavBar/styles.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/ui/screens/Docs/NavBar/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81UXW+jMBB8z6+wVJ3aSoXaTSDEfbnXSvcfKmMWsldjI2ya5E757yc+QoCQ9q5P94jZGe/Mztq37qDAvkqjnUAN5esrA5GgTij5vSBEGmVKTm7SFDZy87wgJBbyLStNpRNObgJBgyUdH3uYiww4qUp1d/tonXAoH1dhGDAZr57iFJZAg41gYbxeRnQTxGGQBn6hs9v7CZHFX8CJNO9QTv4UxqJDozlxpiAStGtLdpi4LSeM0m/15xYw2zpOnkrInxfHhd+pNZUbKGWN0qGuMovFHWPRA6MPIXug/vp+CEc9NOqpgSdoCyUOnKQK9vXdPyvrMD14tbOgHSe2EBK8GNwOQNcVQmGmPXSQW/63GggpRJKgzjihhHVHudh7HSqi/VmZoa6rROXMsHslHFj3A/VbL2E5nnWcrAU7k3gKUscJ9YOJjQr1m+1JVnM+nIstKJCurw6a6hz1qfOga9wUQqI71PdFwUcEPEErYgVJwzSArS5R5jyusL24VVa23rKJsEsQV8I6T25RJTN4OvY3BtUj13Pls072kOjK5jnYOy8BaUrRZl8bDc9XxBCSGu26FaL+5pSefnFKUMLhe0PgTMEJK0bzUiYzfUub+YxfSfDgZuafcqtQg3cK8/m4qd11xyvavCWd9N0WHfyD8OmW1wpe6reolyEaGecmlqNluT6eHHT1Io3uieKxH6d+/i8/TkGQVWlrfGGw7ehDYZhnjbjTMxS1sfieQ4KCWFkCaCJ0Qu4Gz04Q0GJ/3+A+WoMZy46fckf0kvtyXb5EvQwvqS9jPx5gZ+p0gP3x/Aw+72U1I/NKgL+kdR2up/xXYj2z6MfF8Q+nftzKKggAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var container = 'styles_container__1eadind0';
export var inner = 'styles_inner__1eadind2';
export var label = 'styles_label__1eadind7';
export var latestLink = 'styles_latestLink__1eadind3';
export var link = 'styles_link__1eadind8';
export var links = 'styles_links__1eadind4';
export var logo = 'styles_logo__1eadind9';
export var logoImage = 'styles_logoImage__1eadinda';
export var menuIcon = 'styles_menuIcon__1eadindb';
export var outer = 'styles_outer__1eadind1';
export var select = 'styles_select__1eadind5';
export var selector = 'styles_selector__1eadind6';